import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ConnectModal from '../components/ConnectModal'; // Adjust path as necessary
import CubeScene from '../components/CubeScene'; // Adjust path as necessary to where your CubeScene component is located
import Navbar from '../components/Navbar';

const NewHomepage = () => {
    // State to control the visibility of the ConnectModal
    const [isConnectModalOpen, setConnectModalOpen] = useState(false);

    // Function to handle the opening of the ConnectModal
    const openConnectModal = () => {
        console.log('Opening modal');
        setConnectModalOpen(true);
    };

    // Function to handle the closing of the ConnectModal
    const closeConnectModal = () => {
        console.log('Closing modal');
        setConnectModalOpen(false);
    };

    return (<>
        <Navbar/>
        <div className="homepage-container">
            {/* CubeScene component will replace the main content of the homepage */}
            <CubeScene onOpenConnectModal={openConnectModal}/>

            {/* ConnectModal component is controlled by isConnectModalOpen */}
            {isConnectModalOpen && (
                <ConnectModal 
                    isOpen={isConnectModalOpen} 
                    onClose={closeConnectModal} 
                    onSuccessCallback={closeConnectModal}
                />
            )}
        </div>
    </>
     
    );
};

export default NewHomepage;
