// src/actions/auth.js
import Cookies from "js-cookie";
import { thirdwebAuth } from "../consts/thirdwebAuth";
import { VerifyLoginPayloadParams } from "thirdweb/auth";

export const generatePayload = thirdwebAuth.generatePayload;

export async function login(payload) {
  const verifiedPayload = await thirdwebAuth.verifyPayload(payload);
  if (verifiedPayload.valid) {
    const jwt = await thirdwebAuth.generateJWT({
      payload: verifiedPayload.payload,
    });
    Cookies.set("jwt", jwt);
  }
}

export async function isLoggedIn() {
  const jwt = Cookies.get("jwt");
  if (!jwt) {
    return false;
  }

  const authResult = await thirdwebAuth.verifyJWT({ jwt });
  return authResult.valid;
}

export async function logout() {
  Cookies.remove("jwt");
}
