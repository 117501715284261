import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { thirdwebAuth } from '../consts/thirdwebAuth';
import { hasAccess } from '../actions/gate-condition';
import GatedContent from './GatedContent'; // Ensure this import is correct
import Web3Header from '../components/Web3Header';
import { useActiveWallet } from 'thirdweb/react';
import { Box, Typography, Button } from '@mui/material';
import { LoginButton } from '../components/LoginButton';

const Dashboard = () => {
  const activeWallet = useActiveWallet();
  const [loading, setLoading] = useState(true);
  const [authorized, setAuthorized] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const checkAccess = async () => {
      if (!activeWallet) {
        setIsLoggedIn(false);
        setLoading(false);
        return;
      }

      setIsLoggedIn(true);

      const jwt = Cookies.get('jwt');
      if (!jwt) {
        setLoading(false);
        return;
      }


      const authResult = await thirdwebAuth.verifyJWT({ jwt });
      if (!authResult.valid) {
        setLoading(false);
        return;
      }

      const address = authResult.parsedJWT.sub;
      if (!address) {
        setLoading(false);
        return;
      }


      const access = await hasAccess(address);
      setAuthorized(access);
      setLoading(false);
    };

    checkAccess();
  }, [activeWallet]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isLoggedIn) {
    return (
      <div>
        <Web3Header />
        <MustLogin />
      </div>
    );
  }

  if (!authorized) {
    return (
      <div>
        <Web3Header />
        <NotAllowed />
      </div>
    );
  }

  return (
    <div>
      <Web3Header />
      <GatedContent />
    </div>
  );
};

const MustLogin = () => (
  <Box textAlign="center" p={3}>
    <Typography variant="h4" gutterBottom>Este sitio es "Token-gated"</Typography>
    <Typography variant="h6" gutterBottom>Eso significa que hay una puerta "gate" que sólo se abre si tiene un token</Typography>
    <Typography variant="body1" gutterBottom>Conecte su billetera para ver sus opciones.</Typography>
    <LoginButton />
  </Box>
);

const NotAllowed = () => (
  <Box textAlign="center" p={3}>
    <Typography variant="h4" gutterBottom>Acceso Denegado</Typography>
    <Typography variant="h6" gutterBottom>La billetera conectada NO tiene el token requerido. </Typography>
    <Button 
      variant="contained" 
      color="primary" 
      href="/mint" 
      sx={{ mt: 2, backgroundColor: '#d80032', '&:hover': { backgroundColor: '#a60028' } }}
    >
      comprar NFT
    </Button>
  </Box>
);

export default Dashboard;
