"use client";

import { ConnectButton } from 'thirdweb/react';
import { client } from '../consts/client.js';
import { generatePayload, isLoggedIn, login, logout } from '../actions/auth';
import { createWallet } from "thirdweb/wallets";
import { lightTheme } from "thirdweb/react";

const wallets = [
  createWallet("io.metamask"),
  createWallet("com.coinbase.wallet"),
  createWallet("me.rainbow"),
];

const customTheme = lightTheme({
  colors: {
    modalBg: "white",
    primaryButtonBg: "red",
  },
});

export const LoginButton = () => {
  return (
    <ConnectButton
      theme={customTheme}
      autoConnect={true}
      client={client}
      wallets={wallets} 
      auth={{
        isLoggedIn: async (address) => {
          console.log("checking if logged in!", { address });
          return await isLoggedIn();
        },
        doLogin: async (params) => {
          console.log("logging in!");
          await login(params);
        },
        getLoginPayload: async ({ address }) => generatePayload({ address }),
        doLogout: async () => {
          console.log("logging out!");
          await logout();
        },
      }}
    />
  );
};
