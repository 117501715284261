import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Container, Typography, Box, Divider } from '@mui/material';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';

const ContentBlockHero = ({ title, subtitle, content }) => {
  return (
    <Container maxWidth="lg">
      <Box 
        display="flex" 
        flexDirection={{ xs: 'column', sm: 'row' }} 
        justifyContent="space-between" 
        alignItems="flex-start" 
        py={5}
      >
        <Box display="flex" alignItems="flex-start" width={{ xs: '100%', sm: '30%' }} mb={{ xs: 2, sm: 0 }}>
          <SubdirectoryArrowRightIcon sx={{ marginRight: 1 }} />
          <Typography variant="h1" component="h1"  gutterBottom>{title}</Typography>
        </Box>
        <Box width={{ xs: '100%', sm: '65%' }}>
          <Typography variant="h2" component="h2"  gutterBottom>{subtitle}</Typography>
          <Typography variant="body1">
            {documentToReactComponents(content)}
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ marginBottom: 5 ,  opacity: '1'}} />
    </Container>
  );
};

export default ContentBlockHero;
