import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { useNavigate } from 'react-router-dom';

const CubeScene = ({ onOpenConnectModal }) => {
  const mountRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Enable color management in Three.js
    THREE.ColorManagement.legacyMode = false;

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.z = 8;

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight - 66);
    renderer.outputColorSpace = THREE.SRGBColorSpace; // Set renderer to use SRGB color space

    mountRef.current.appendChild(renderer.domElement);

    window.addEventListener('resize', () => {
      renderer.setSize(window.innerWidth, window.innerHeight);
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
    });

    const light = new THREE.DirectionalLight(0xffffff, 1);
    light.position.set(20, 20, 20);
    scene.add(light);

    const loader = new THREE.TextureLoader();
    const textures = [
      loader.load('ABOUT.png', (texture) => { texture.colorSpace = THREE.SRGBColorSpace; }),
      loader.load('PROJECTS.png', (texture) => { texture.colorSpace = THREE.SRGBColorSpace; }),
      loader.load('CONNECT.png', (texture) => { texture.colorSpace = THREE.SRGBColorSpace; }),
      loader.load('BLOG.png', (texture) => { texture.colorSpace = THREE.SRGBColorSpace; }),
      loader.load('TECH-ORANGE.png', (texture) => { texture.colorSpace = THREE.SRGBColorSpace; }),
      loader.load('REBEL.png', (texture) => { texture.colorSpace = THREE.SRGBColorSpace; }),
    ];

    const materials = textures.map(texture => new THREE.MeshStandardMaterial({
      map: texture,
      roughness: 0,
      metalness: 0
    }));

    const geometry = new THREE.BoxGeometry(5, 5, 5);
    const cube = new THREE.Mesh(geometry, materials);
    scene.add(cube);

    const ambientLight = new THREE.AmbientLight(0xffffff, 3);
    scene.add(ambientLight);

    // Add stars
    const starGeometry = new THREE.BufferGeometry();
    const starMaterial = new THREE.PointsMaterial({ color: 0xffffff });

    const starVertices = [];
    for (let i = 0; i < 10000; i++) {
      const x = THREE.MathUtils.randFloatSpread(2000);
      const y = THREE.MathUtils.randFloatSpread(2000);
      const z = THREE.MathUtils.randFloatSpread(2000);
      starVertices.push(x, y, z);
    }

    starGeometry.setAttribute('position', new THREE.Float32BufferAttribute(starVertices, 3));
    const stars = new THREE.Points(starGeometry, starMaterial);
    scene.add(stars);

    let isDragging = false;
    let startDragPoint = { x: 0, y: 0 };
    let wasDragged = false;
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    const updateMousePosition = (event) => {
      event.preventDefault();
      let clientX, clientY;
      if (event.changedTouches) {
        clientX = event.changedTouches[0].clientX;
        clientY = event.changedTouches[0].clientY;
      } else {
        clientX = event.clientX;
        clientY = event.clientY;
      }
      mouse.x = (clientX / window.innerWidth) * 2 - 1;
      mouse.y = - (clientY / window.innerHeight) * 2 + 1;
    };

    const onPointerDown = (event) => {
      isDragging = true;
      wasDragged = false;
      updateMousePosition(event);
      startDragPoint.x = event.changedTouches ? event.changedTouches[0].clientX : event.clientX;
      startDragPoint.y = event.changedTouches ? event.changedTouches[0].clientY : event.clientY;
    };

    const onPointerMove = (event) => {
      if (!isDragging) return;
      wasDragged = true;
      updateMousePosition(event);

      const endX = event.changedTouches ? event.changedTouches[0].clientX : event.clientX;
      const endY = event.changedTouches ? event.changedTouches[0].clientY : event.clientY;
      const deltaX = endX - startDragPoint.x;
      const deltaY = endY - startDragPoint.y;
      const rotationSpeed = 0.005;

      cube.rotation.y -= deltaX * rotationSpeed;
      cube.rotation.x += deltaY * rotationSpeed;

      startDragPoint = { x: endX, y: endY };
    };

    const onPointerUp = (event) => {
      if (!wasDragged) {
        raycaster.setFromCamera(mouse, camera);
        const intersects = raycaster.intersectObjects([cube]);
        if (intersects.length > 0) {
          const faceIndex = intersects[0].face.materialIndex;
          switch (faceIndex) {
            case 0: navigate('/about'); break;
            case 1: navigate('/mint'); break;
            case 2: onOpenConnectModal(); break;
            case 3: navigate('/dashboard'); break;
            default: break;
          }
        }
      }
      isDragging = false;
    };

    document.addEventListener('mousedown', onPointerDown, false);
    document.addEventListener('mousemove', onPointerMove, false);
    document.addEventListener('mouseup', onPointerUp, false);
    document.addEventListener('touchstart', onPointerDown, false);
    document.addEventListener('touchmove', onPointerMove, false);
    document.addEventListener('touchend', onPointerUp, false);

    const animate = () => {
      requestAnimationFrame(animate);
      if (!isDragging) {
        cube.rotation.x += 0.0001;
        cube.rotation.y += 0.003;
      }
      renderer.render(scene, camera);
    };

    animate();

    return () => {
      if (mountRef.current && renderer.domElement.parentNode === mountRef.current) {
        mountRef.current.removeChild(renderer.domElement);
      }
      document.removeEventListener('mousedown', onPointerDown, false);
      document.removeEventListener('mousemove', onPointerMove, false);
      document.removeEventListener('mouseup', onPointerUp, false);
      document.removeEventListener('touchstart', onPointerDown, false);
      document.removeEventListener('touchmove', onPointerMove, false);
      document.removeEventListener('touchend', onPointerUp, false);
    };
  }, [navigate, onOpenConnectModal]);

  return <div ref={mountRef}></div>;
};

export default CubeScene;
