"use client";

import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';

const GatedContent = () => {
  return (
    <Box p={3} textAlign="center">
      <Typography variant="h4" gutterBottom>Sí tiene el token!</Typography>
      <Typography variant="h6" gutterBottom>Se ha validado que tenga el Token de acceso. Puede proceder a agendar su llamada</Typography>
      <Grid container justifyContent="center" spacing={2} sx={{ mt: 2 }}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            href="https://calendly.com/alberto-product-manager/gated-call"
            target="_blank"
            sx={{ backgroundColor: '#d80032', '&:hover': { backgroundColor: '#a60028' }, marginRight: '10%' }}
          >
            Agendar
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            href="https://app.charmverse.io/join?domain=tech-rebel-space"
            target="_blank"
            sx={{ backgroundColor: '#d80032', '&:hover': { backgroundColor: '#a60028' } }}
          >
            Acceso Charmverse
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GatedContent;
