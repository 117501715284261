// src/services/contentful.js

import { createClient } from 'contentful';

const client = createClient({
  space: '1egiyry6ln63',
  accessToken: 'acGKu4VM_pn90KtqOTqsIa8Slat17wlZKZ-MRAkDSXc'
});

export default client;
