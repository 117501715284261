import React from 'react';
import { Container, Grid, Typography, Box, List, ListItem, ListItemText, Button, Card, CardContent, Paper, Link } from '@mui/material';
import Navbar from '../components/Navbar';

function MintPage() {
    return (
        <>
        <Navbar/>
        <Container>
            {/* Hero Section */}
            <Box my={8}>
                <Grid container spacing={6} alignItems="center">
                    <Grid item xs={12} md={4}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="h2" component="h1" gutterBottom>
                                    Accese el mundo de TechRebel!
                                </Typography>
                                <List>
                                    <ListItem>
                                        <ListItemText primary="🌟 Sesión 1:1 con TechRebel" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="📚 Acceso ilimitado al repositorio de recursos" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="🚀 Participa en proyectos innovadores de Web3" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="💡 Aprende sobre Web3 experimentando Web3" />
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Box display="flex" justifyContent="center">
                            <iframe
                                src="https://embed.ipfscdn.io/ipfs/bafybeicd3qfzelz4su7ng6n523virdsgobrc5pcbarhwqv3dj3drh645pi/?contract=0x7735A02f18eA1C6AF04B3290243ec0f69902A677&chain=%7B%22name%22%3A%22Base%22%2C%22chain%22%3A%22ETH%22%2C%22rpc%22%3A%5B%22https%3A%2F%2F8453.rpc.thirdweb.com%2F%24%7BTHIRDWEB_API_KEY%7D%22%5D%2C%22nativeCurrency%22%3A%7B%22name%22%3A%22Ether%22%2C%22symbol%22%3A%22ETH%22%2C%22decimals%22%3A18%7D%2C%22shortName%22%3A%22base%22%2C%22chainId%22%3A8453%2C%22testnet%22%3Afalse%2C%22slug%22%3A%22base%22%2C%22icon%22%3A%7B%22url%22%3A%22ipfs%3A%2F%2FQmW5Vn15HeRkScMfPcW12ZdZcC2yUASpu6eCsECRdEmjjj%2Fbase-512.png%22%2C%22width%22%3A512%2C%22height%22%3A512%2C%22format%22%3A%22png%22%7D%7D&clientId=6e8fadd3ad78b9ff45f27adc83ad7917&theme=light&primaryColor=red"
                                width="100%"
                                height="600px"
                                frameBorder="0"
                                style={{ maxWidth: '100%' }}
                            ></iframe>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            {/* Steps Section */}
            <Box my={8}>
                <Typography variant="h4" component="h2" gutterBottom align="center">
                    Cómo funciona:
                </Typography>
                <Box maxWidth="md" mx="auto">
                    <Paper elevation={3} sx={{ p: 4 }}>
                    <List>
                            <ListItem>
                                <ListItemText 
                                    primary={
                                        <span>
                                            1. Para "tener" el NFT necesita una billetera. Recomiendo la de <Link href="https://www.coinbase.com/es-LA/wallet" target="_blank" sx={{ color: '#d80032' }}>Coinbase</Link>.
                                        </span>
                                    } 
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="2. Conecte su billetera haciendo clic en el botón 'Connect Wallet'. Para pagar necesita ETH sobre la red Base. La billetera de Coinbase tiene opciones para converitr Eth de Mainnet a Base" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="3. Seleccione la cantidad de NFTs que desea mintear. Confirme la transacción en su billetera." />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="4. Recibirá el NFT en su billetera." />
                            </ListItem>
                            <ListItem>
                                <ListItemText 
                                    primary={
                                        <span>
                                            5. Accese el <Link href="/dashboard" target="_blank" sx={{ color: '#d80032' }}>Dashboard</Link>, conecte su billetera y listo!
                                        </span>
                                    } 
                                />
                            </ListItem>
                        </List>
                    </Paper>
                </Box>
            </Box>
        </Container>
        </>
        
    );
}

export default MintPage;
