import React, { useState, useEffect } from 'react';
import client from '../../services/contenful';
import { Box, Typography, Avatar, IconButton, Container } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CloseIcon from '@mui/icons-material/Close';
import { useLocale } from '../LocaleContext';

function ContentBlockAuthor({ authorName }) {
  const [author, setAuthor] = useState(null);
  const [whoWeAre, setWhoWeAre] = useState('');
  const { locale } = useLocale();

  useEffect(() => {
    client.getEntries({
      'content_type': 'componentAuthor',
      'fields.name': authorName,
      locale: locale
    })
    .then((response) => {
      if (response.items.length > 0) {
        setAuthor(response.items[0]);
      }
    })
    .catch((error) => {
      console.error("Error fetching author from Contentful:", error);
    });

    client.getEntry('5DPM3kHwaJ0hQLgOWxcuX4', { locale: locale })
      .then((entry) => {
        setWhoWeAre(entry.fields.whoWeAre);
      })
      .catch((error) => {
        console.error("Error fetching whoWeAre field from Contentful:", error);
      });
  }, [authorName, locale]);

  if (!author) {
    return null; // or a MUI loading spinner/placeholder could be used here
  }

  return (
    <Container maxWidth="lg">
      <Box 
        display="flex" 
        flexDirection={{ xs: 'column', sm: 'row' }} 
        justifyContent="space-between" 
        alignItems="flex-start" 
        py={5}
      >
        <Box width={{ xs: '100%', sm: '30%' }} mb={{ xs: 2, sm: 0 }}>
          <Typography variant="h2" component="h2" gutterBottom>{whoWeAre}</Typography>
        </Box>
        <Box 
          display="flex" 
          flexDirection={{ xs: 'column', sm: 'row' }} 
          width={{ xs: '100%', sm: '65%' }}
          alignItems="flex-start"
        >
          <Avatar 
            alt={author.fields.name} 
            src={author.fields.avatar.fields.file.url} 
            sx={{ width: 100, height: 100, mb: { xs: 2, sm: 0 } }} 
          />
          <Box ml={{ xs: 0, sm: 2 }} display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start">
            <Typography variant="h4" component="h3" gutterBottom>
              {author.fields.name}
            </Typography>
            <Typography variant="h6" component="h4" gutterBottom>
              {author.fields.surname}
            </Typography>
            <Typography variant="body1" component="p" gutterBottom>
              {author.fields.shortBio}
            </Typography>
            <Box display="flex" mt={1}>
              {author.fields.linkedin && (
                <IconButton 
                  component="a" 
                  href={author.fields.linkedin} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  sx={{ color: 'primary.main', mr: 1 }}
                >
                  <LinkedInIcon />
                </IconButton>
              )}
              {author.fields.x && (
                <IconButton 
                  component="a" 
                  href={author.fields.x} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  sx={{ color: 'primary.main' }}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default ContentBlockAuthor;
