import React, { useEffect, useState } from 'react';
import client from '../services/contenful';
import { Container, Box,  Button } from '@mui/material';
import Navbar from '../components/Navbar';
import { useLocale } from '../components/LocaleContext';
import ConnectModal from '../components/ConnectModal';
import ContentBlockHero from '../components/content-block/Hero';
import ContentBlockAuthor from '../components/content-block/Author';

const AboutUs = () => {
  const [pageContent, setPageContent] = useState(null);
  const [isConnectModalOpen, setConnectModalOpen] = useState(false);
  const { locale } = useLocale();


  const openConnectModal = () => {
    setConnectModalOpen(true);
  };

  const closeConnectModal = () => {
    setConnectModalOpen(false);
  };

  useEffect(() => {
    client.getEntry('5DPM3kHwaJ0hQLgOWxcuX4', { locale: locale, include: 2 })
      .then((entry) => {
        setPageContent(entry.fields);
      })
      .catch((error) => console.log('Error fetching entry:', error));
  }, [locale]);

  useEffect(() => {
    console.log('Page content:', pageContent);
  }, [pageContent]);

  if (!pageContent) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Navbar />
      <Container
        maxWidth={false}
        sx={{
          backgroundColor: 'white',
          color: 'black',
          minHeight: '100vh',
          paddingBottom: '10vh',
          padding: '0vh',
          margin: '0',
        }}
      >
        <ContentBlockHero 
          title={pageContent.title} 
          subtitle={pageContent.heroSubtitle}  
          content={pageContent.heroSection} 
        />
                <ContentBlockHero 
          title={pageContent.product_1_title} 
          subtitle={pageContent.product1Subtitle}  
          content={pageContent.product1Content} 
        />
         <ContentBlockAuthor authorName="Alberto Chaves" />

        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '40px' }}>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={openConnectModal}
            sx={{ fontSize: '1.25rem' }}
          >
            {pageContent.ctaText}
          </Button>
        </Box>
      </Container>
      {isConnectModalOpen && (
        <ConnectModal
          isOpen={isConnectModalOpen}
          onClose={closeConnectModal}
          onSuccessCallback={closeConnectModal}
        />
      )}
    </>
  );
};

export default AboutUs;
