import React from 'react';
import { Box, Typography } from '@mui/material';
import { LoginButton } from './LoginButton'; // Adjust the import path as necessary

const Web3Header = () => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={2}
      bgcolor="white"
      color="black"
    >
      <Box
        component="a"
        href="/"
        sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}
      >
        <img src="/logo.png" alt="Logo" style={{ height: '60px' }} />
      </Box>

      <Box>
        <LoginButton />
      </Box>
    </Box>
  );
};

export default Web3Header;
