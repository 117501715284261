import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Box, Drawer, List, ListItem, ListItemText, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useLocale } from './LocaleContext';
import ConnectModal from '../components/ConnectModal'; // Adjust path as necessary

const Navbar = () => {
    const { locale, setLocale } = useLocale();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isConnectModalOpen, setConnectModalOpen] = useState(false);

    const handleDrawerOpen = () => {
        setSidebarOpen(true);
    };

    const handleDrawerClose = () => {
        setSidebarOpen(false);
    };

    const openConnectModal = () => {
        console.log('Opening modal');
        setConnectModalOpen(true);
        handleDrawerClose(); // Close sidebar when modal opens
    };

    const closeConnectModal = () => {
        console.log('Closing modal');
        setConnectModalOpen(false);
    };

    return (
        <Box>
            <AppBar position="sticky" color="transparent" elevation={0} sx={{ borderBottom: '2px solid black', width: '100%' }}>
                <Toolbar sx={{ justifyContent: 'space-between', maxWidth: '100%' }}>
                    <Box
                        component={Link}
                        to="/"
                        sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
                    >
                        <img src="/logo.png" alt="Logo" style={{ height: '60px' }} />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                            color="inherit"
                            onClick={() => setLocale(locale === 'en-US' ? 'es-ES' : 'en-US')}
                            sx={{ color: '#000', marginRight: '16px' }}
                        >
                            {locale === 'en-US' ? 'ES' : 'EN'}
                        </Button>
                        <IconButton
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                            onClick={handleDrawerOpen}
                            sx={{ color: '#000' }}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer anchor="right" open={sidebarOpen} onClose={handleDrawerClose} sx={{ '.MuiDrawer-paper': { width: '250px' } }}>
                <Box
                    sx={{ display: 'flex', flexDirection: 'column', height: '100%', p: 2 }}
                    role="presentation"
                >
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 3 }}>
                        <IconButton onClick={handleDrawerClose} sx={{ position: 'absolute', top: 16, right: 16 }}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <List sx={{ flexGrow: 1, mt: 6 }}> {/* Added margin top to provide spacing */}
                        <ListItem button component={Link} to="/" sx={{ fontSize: '1.25rem', color: 'black', fontFamily: 'monospace' }}>
                            <ListItemText primary="Home" />
                        </ListItem>
                        <ListItem button component={Link} to="/about" sx={{ fontSize: '1.25rem', color: 'black', fontFamily: 'monospace' }}>
                            <ListItemText primary="About" />
                        </ListItem>
                        <ListItem button component={Link} to="/dashboard" sx={{ fontSize: '1.25rem', color: 'black', fontFamily: 'monospace' }}>
                            <ListItemText primary="Dashboard" />
                        </ListItem>
                        <ListItem button onClick={openConnectModal} sx={{ fontSize: '1.25rem', color: 'black', fontFamily: 'monospace' }}>
                            <ListItemText primary="Contact" />
                        </ListItem>
                    </List>
                    <Box sx={{ p: 2 }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            component={Link}
                            to="/mint"
                            sx={{
                                backgroundColor: '#d80032',
                                color: '#fff',
                                '&:hover': {
                                    backgroundColor: '#a60028'
                                },
                                width: '100%',
                                fontSize: '1.25rem',
                                fontFamily: 'monospace'
                            }}
                        >
                            Mint
                        </Button>
                    </Box>
                </Box>
            </Drawer>
            {isConnectModalOpen && (
                <ConnectModal
                    isOpen={isConnectModalOpen}
                    onClose={closeConnectModal}
                    onSuccessCallback={closeConnectModal}
                />
            )}
        </Box>
    );
};

export default Navbar;
