import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Homepage from './pages/Homepage';
import Projects from './pages/Projects';
import AboutUs from './pages/AboutUs';
import BlogPage from './pages/BlogPage';
import BlogPostPage from './pages/BlogPostPage';
import SingleProjectPage from './pages/SingleProjectPage';
import { LocaleProvider } from './components/LocaleContext';
import AuthorBioPage from './pages/AuthorBioPage';
import Deck from './pages/Deck';
import MintPage from './pages/MintPage';
import Dashboard from './pages/Dashboard';
import { ThirdwebProvider } from 'thirdweb/react';


const theme = createTheme({
  palette: {
    primary: {
      main: '#D80032',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: '#d80032 !important',
        },
      },
    },
  },
  typography: {
    body1: {
      fontSize: '1.4rem', // Adjusted font size
      fontWeight: 300,      // Adjusted font weight
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // Adjusted font family
    },
    h1: {
      fontSize: '3rem',
      fontWeight: 500,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    h2: {
      fontSize: '1.9rem',
      fontWeight: 600,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 500,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    h4: {
      fontSize: '1.75rem',
      fontWeight: 400,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  },
});

const App = () => {


  return (
    <>
    <ThemeProvider theme={theme}>
    <LocaleProvider>
    <ThirdwebProvider clientId={process.env.REACT_APP_THIRDWEB_CLIENT_ID}>

        <Router>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/post/:slug" element={<BlogPostPage />} />
            <Route path="/project/:id" element={<SingleProjectPage />} />
            <Route path="/author/:id" element={< AuthorBioPage />} />
            <Route path="/deck" element={<Deck/>} />
            <Route path="/mint" element={<MintPage/>} />
            <Route path="/dashboard" element={<Dashboard/>} />
            
          </Routes>
        </Router>
        </ThirdwebProvider>

      </LocaleProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
