import { createAuth } from "thirdweb/auth";
import { privateKeyToAccount } from "thirdweb/wallets";
import { client } from "./client";

const privateKey = process.env.REACT_APP_THIRDWEB_ADMIN_PRIVATE_KEY|| "";

if (!privateKey) {
  throw new Error("Missing REACT_APP_ADMIN_PRIVATE_KEY in .env file.");
}

if (privateKey.length !== 64) {
  throw new Error("Private key must be a 64-character hexadecimal string.");
}

export const thirdwebAuth = createAuth({
  domain: process.env.REACT_APP_THIRDWEB_AUTH_DOMAIN || "",
  adminAccount: privateKeyToAccount({ client, privateKey }),
});
