import { getContract } from 'thirdweb';
import { base } from 'thirdweb/chains';
import { client } from '../consts/client';
import { balanceOf as balanceOfERC721 } from 'thirdweb/extensions/erc721';

export async function hasAccess(address) {
  return await example_hasSomeErc721Tokens(address);
}

async function example_hasSomeErc721Tokens(address) {
  const requiredQuantity = 1;

  const erc721Contract = getContract({
    address: process.env.REACT_APP_ERC721_CONTRACT_ADDRESS,
    chain: base,
    client,
  });


  try {
    const ownedBalance = await balanceOfERC721({
      contract: erc721Contract,
      owner: address,
    });


    return ownedBalance >= requiredQuantity;
  } catch (error) {
    return false;
  }
}
